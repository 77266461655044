import useAxios from 'hooks/useAxios'
import { useState } from 'react'
import { getFanLevels, updateAllUsersFanLevel } from 'services/SuperfanService'
import { showMessage } from 'utils/notification.util'

const useFanLevelTable = () => {
	const [levels, setLevels] = useState([])

	const afterFetchLevels = (data, error) => {
		if (!error) {
			setLevels(data)
		}
	}

	const [{ loading }] = useAxios(getFanLevels(), afterFetchLevels)

	const afterSyncLevels = (data, error) => {
		if (!error) {
			showMessage({ message: 'Superfan levels synced successfully' })
		}
	}

	const [, syncLevels] = useAxios(updateAllUsersFanLevel(), afterSyncLevels)

	return {
		loading,
		levels,
		syncLevels,
	}
}

export default useFanLevelTable
