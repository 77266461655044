import { buildFormData } from 'utils/form.util'

export const getUsers = (params) => ({
  method: 'get',
  url: '/profile',
  params,
  autoexec: false,
})

export const getDashboardUsers = (params) => ({
  method: 'get',
  url: '/profile/dashboard',
  params,
  autoexec: false,
})

export const getUserById = (autoexec, id) => ({
  method: 'get',
  url: `/profile/${id}`,
  autoexec,
})

export const getProfileCounts = (autoexec, id) => ({
  method: 'get',
  url: `/profile/counts/${id}`,
  autoexec,
})

export const addUser = (data) => ({
  method: 'post',
  url: '/user',
  data,
  autoexec: false,
})

export const editWallet = (data) => ({
  method: 'put',
  url: '/wallet',
  data,
  autoexec: false,
})

export const editUser = (data) => {
  const fields = [
    'id',
    'email',
    'displayName',
    'firstName',
    'genderId',
    'username',
    'lastName',
    'about',
    'bio',
    'typeId',
    'isAdmin',
    'featured',
    'shortUrl',
    'password',
    'countryId',
    'isAirdropFan',
    'isAirdropCreator',
    'isVesting',
    'campaignId',
    'isBlacklisted',
    'isBanned',
    'banReason',
    'adminNote',
  ]
  const files = ['headerImg', 'avatar']
  const objects = ['socialNetworks']
  const formData = buildFormData(data, fields, files, objects)
  return {
    method: 'put',
    url: '/profile',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    autoexec: false,
  }
}

export const getRestrictedUsers = (params) => ({
  method: 'get',
  url: '/user/restriction',
  params,
  autoexec: false,
})

export const setUserRestriction = (data) => ({
  method: 'put',
  url: '/user/restriction',
  data,
  autoexec: false,
})

export const updateWithdrawalRequireKyc = (data) => ({
  method: 'put',
  url: '/user/withdrawalRequireKyc',
  data,
  autoexec: false,
})

export const deleteUserRestriction = (userId) => ({
  method: 'delete',
  url: '/user/restriction',
  data: { userId },
  autoexec: false,
})

export const getUsersList = (params) => ({
  method: 'get',
  url: '/user/list',
  params,
  autoexec: false,
})
