import _ from "lodash";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import useLockedTRSTable from "./useLockedTRSTable";
import PageTitle from "jsx/layouts/PageTitle";
import { CustomTable } from "components/common/customTable";
import { SearchField } from "components/common/searchField";
import { formatDate } from "utils/date.utils";
import { formatShortAddress } from "utils/common.util";
import { deployLockedTRSVestingContract } from "services/LockedTRSService";

const environment = process.env.REACT_APP_ENVIRONMENT;

const LockedTRSTable = () => {
  const polygonscanLinkPrefix =
    environment === "production"
      ? "https://polygonscan.com/address/"
      : "https://amoy.polygonscan.com/address/";

  const {
    data,
    limit,
    loading,
    setOffset,
    setLimit,
    setSearch,
    setDeployVestingContractId,
  } = useLockedTRSTable();

  const columns = [
    {
      header: "Email",
      key: "email",
    },
    {
      header: "Total Amount",
      key: "totalAmount",
    },
    {
      header: "Start Date",
      render: (row) => formatDate(_.get(row, "startDate", null), "timestamp"),
    },
    {
      header: "End Date",
      render: (row) => formatDate(_.get(row, "endDate", null), "timestamp"),
    },
    {
      header: "Vesting Period",
      render: (row) => `${row?.vestingPeriod} Months`,
    },
    {
      header: "Tag",
      render: (row) => row?.lockedTRSType?.name,
    },
    {
      header: "Vesting Contract",
      render: (row) =>
        !_.isNil(row?.vestingContractAddress) ? (
          <a
            href={`${polygonscanLinkPrefix}${row?.vestingContractAddress}`}
            target="blank"
          >
            {formatShortAddress(row?.vestingContractAddress)}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
              />
            </svg>
          </a>
        ) : (
          <Button
            className="mr-2"
            variant="danger"
            onClick={() => {
              setDeployVestingContractId(row.id);
            }}
          >
            Deploy
          </Button>
        ),
    },
  ];

  return (
    <>
      <PageTitle title={"Locked TRS Users"} />

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Current Users</Card.Title>

              <Link to="add-locked-trs-user">
                <Button className="mr-2" variant="success">
                  Add User
                </Button>
              </Link>
              <SearchField {...{ setSearch }} />
            </Card.Header>
            <Card.Body>
              <CustomTable
                {...{
                  data,
                  loading,
                  limit,
                  setLimit,
                  setOffset,
                  columns,
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LockedTRSTable;
