import { Row, Col, Card, Button } from 'react-bootstrap'
import usePerksProgram from './usePerksProgram'
import { formatDate } from 'utils/date.utils'
import { CustomTable } from 'components/common/customTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { SearchField } from 'components/common/searchField'
import styles from './PerksProgram.module.scss'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const PerksProgram = () => {
	const {
		data,
		loading,
		limit,
		projectsToAdd,
		showAddProjects,
		setProjectsToAdd,
		handleShowAddProjects,
		removeProjectFromPerk,
		handleAddProjects,
		setOffset,
		setLimit,
		setSearch,
	} = usePerksProgram()

	const columns = [
		{
			header: 'Created On',
			render: (row) => formatDate(row.createdAt, 'inputDate'),
		},
		{
			header: 'Project',
			render: (row) => (
				<a href={`${frontBaseURL}/project/${row.slug}`} target="_blank" rel="noreferrer">
					{row.title}
				</a>
			),
		},
		{
			header: 'Creator',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row.user.username}`} target="_blank" rel="noreferrer">
					<div className="d-flex align-items-center">
						<img src={row?.user?.avatar} className="rounded-md mr-2" width="24" alt="" />{' '}
						{row.user.displayName}
					</div>{' '}
				</a>
			),
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Button onClick={removeProjectFromPerk(row?.id)} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-trash"></i>
					</Button>
				</div>
			),
		},
	]

	return (
		<>
			<PageTitle title={'Perks Program Management'} />
			{showAddProjects && (
				<Row className={styles.project_table}>
					<Col lg={12}>
						<Card>
							<Card.Body>
								<div className="form-group col-md-12">
									<label>Project slugs (Add the projects slugs split by comma)</label>
									<textarea
										value={projectsToAdd}
										className="form-control"
										rows="4"
										placeholder="Project slugs"
										onChange={(e) => {
											setProjectsToAdd(e.target.value)
										}}
									/>
								</div>
								<div className="form-group col-md-12">
									<Button className="mr-2" variant="success" onClick={handleAddProjects}>
										Save
									</Button>
									<Button className="mr-2" variant="danger" onClick={handleShowAddProjects(false)}>
										Close
									</Button>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}
			<Row className={styles.project_table}>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Projects</Card.Title>
							<Button className="mr-2" variant="success" onClick={handleShowAddProjects(true)}>
								Add Projects
							</Button>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default PerksProgram
