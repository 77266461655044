import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { showMessage } from 'utils/notification.util'
import {
	addPerksProgramProjectsService,
	deletePerksProgramProjectsService,
	getPerksProgramProjectsService,
} from 'services/AdminService'
import useAxiosV2 from 'hooks/useAxiosV2'

const usePerksProgram = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [showAddProjects, setShowAddProjects] = useState(false)
	const [projectsToAdd, setProjectsToAdd] = useState([])
	const [projects, setProjects] = useState({
		rows: [],
		count: 0,
	})
	const { axiosService } = useAxiosV2()

	const afterFetchProjects = (data, error) => {
		if (!error) {
			setProjects(data)
		}
	}

	const [{ loading: loadingProjects }, fetchProjects] = useAxios(
		getPerksProgramProjectsService({ limit, offset, search }),
		afterFetchProjects
	)

	const [{ loading: addingProjects }, handleAddProjects] = useAxios(
		addPerksProgramProjectsService({ projectsToAdd }),
		() => {
			fetchProjects()
			setProjectsToAdd([])
			setShowAddProjects(false)
			showMessage({ message: 'Projects added successfully!' })
		}
	)

	const removeProjectFromPerk = (projectId) => () => {
		console.log({ projectId })
		axiosService(deletePerksProgramProjectsService({ projectId }), (_data, error) => {
			if (!error) {
				fetchProjects()
				showMessage({ message: 'Projects deleted successfully!' })
			}
		})
	}

	const handleShowAddProjects = (showForm) => () => {
		setShowAddProjects(showForm)
	}

	const loading = loadingProjects || addingProjects

	useEffect(() => {
		fetchProjects()
	}, [limit, offset, search])

	return {
		loading,
		data: projects,
		limit,
		showAddProjects,
		handleShowAddProjects,
		removeProjectFromPerk,
		handleAddProjects,
		setProjectsToAdd,
		setLimit,
		setOffset,
		setSearch,
	}
}

export default usePerksProgram
