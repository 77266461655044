import _ from 'lodash'

export const addObjectToLocalStorage = (data) => {
	Object.keys(data).forEach((key) => {
		let value = _.get(data, [key], '')
		if (typeof value === 'object') value = JSON.stringify(value)
		localStorage.setItem(key, value)
	})
}

export const validateLocalStorageKey = (key, value) => {
	let storedValue = localStorage.getItem(key)
	if (storedValue === value) return true
	return false
}

export const formatShortAddress = (hash) =>
	_.isNil(hash) ? '' : `${hash.slice(0, 6)}...${hash.slice(-4)}`

function isObjectString(str) {
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}

export const getObjectFromLocalStorage = (keys) => {
	return keys.reduce((acc, key) => {
		let value = localStorage.getItem(key)
		if (isObjectString(value)) value = JSON.parse(value)
		acc[key] = isNaN(key) ? value : Number(value)
		return acc
	}, {})
}

export const isAuthenticated = (user) => {
	if (!user) return false
	const { token } = getObjectFromLocalStorage(['token'])
	return user.isAdmin === 1 && token === user.jwt
}
