import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import {
	getDiamondCircleTypes,
	addDiamondCircleUser,
	restrictDiamondCircleUser
} from 'services/DiamondCircleService'
import _ from 'lodash'

const useDiamondCircleForm = () => {
	const history = useHistory()
	const [data, setData] = useState({
		email: null,
		typeId: null,
		startDate: null
	})
	const [types, setTypes] = useState([])
	const [restrictionData, setRestrictionData] = useState({
		email: null,
		isRestricted: null
	})

	const afterFetchTypes = (response, error) => {
		if (!error) {
			setTypes([...response])
		}
	}
	const [{ loading: loadingOptions }, getOptions] = useAxios(getDiamondCircleTypes(), afterFetchTypes)

	const afterAdd = (_data, error) => {
		if (!error) {
			const message = `User with email ${data.email} added successfully!`
			showMessage({ message })
			history.push('/diamond-circle')
		} else {
			showMessage({ message: _data.message, type: 'error' })
		}
	}

	const afterRestrict = (_data, error) => {
		if (!error) {
			const message = `Restriction status for ${restrictionData.email} was set to ${restrictionData.isRestricted === 1 ? 'True' : 'False'}!`
			showMessage({ message })
			history.push('/diamond-circle')
		} else {
			showMessage({ message: _data.message, type: 'error' })
		}
	}

	const service = addDiamondCircleUser(data)
	const [{ loading: adding }, addUser] = useAxios(service, afterAdd)

	const restrictionService = restrictDiamondCircleUser(restrictionData)
	const [{ loading: restricting }, restrictUser] = useAxios(restrictionService, afterRestrict)

	const handleChange = (state) => {
		setData({
			...data,
			...state
		})
	}

	const handleRestrictionChange = (state) => {
		setRestrictionData({
			...restrictionData,
			...state
		})
	}

	const handleChangeType = (type) => {
		setData({
			...data,
			typeId: type
		})
	}

	const handleSubmit = () => {
		if (_.isNil(data.email) || _.isNil(data.startDate) || _.isNil(data.typeId)) {
			showMessage({ message: "Please make sure to fill all the required fields!", type: 'error' })
		} else {
			addUser()
		}
	}

	const handleRestrictionSubmit = () => {
		if (_.isNil(restrictionData.email) || _.isNil(restrictionData.isRestricted)) {
			showMessage({ message: "Please make sure to fill all the required fields!", type: 'error' })
		} else {
			restrictUser()
		}
	}

	const handleCancel = () => {
		history.goBack()
	}

	const loading = adding || loadingOptions || restricting

	useEffect(async () => {
		await getOptions()
	}, [])

	return {
		types,
		data,
		loading,
		restrictionData,
		handleCancel,
		handleChange,
		handleChangeType,
		handleSubmit,
		handleRestrictionChange,
		handleRestrictionSubmit
	}
}

export default useDiamondCircleForm
