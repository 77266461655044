import { Button } from 'react-bootstrap'
import useDiamondCircleForm from './useDiamondCircleForm'
import { LoadMask } from 'components/common/loadMask'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { CustomSelect } from 'components/common/customSelect'

const DiamondCircleForm = () => {
	const {
		types,
		data,
		loading,
		restrictionData,
		handleCancel,
		handleChange,
		handleChangeType,
		handleSubmit,
		handleRestrictionChange,
		handleRestrictionSubmit
	} = useDiamondCircleForm()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="column">
					<div className="row">
						<div className="col-xl-12 col-lg-12">
							<div className="card">
								<div className="card-header">
									<h4 className="card-title">Add a new user to Diamond Circle Bucket</h4>
								</div>
								<div className="card-body">
									<div className="basic-form">
										<form onSubmit={(e) => e.preventDefault()}>
											<div className="form-row">
												<div className="form-group col-md-4">
													<label>Email*</label>
													<input
														value={data.email}
														type="text"
														className="form-control"
														placeholder="Email"
														onChange={(e) => {
															handleChange({ email: e.target.value })
														}}
													/>
												</div>
												<div className="form-group col-md-4">
													<label>Start Date*</label>
													<DatePicker selected={data?.startDate} onChange={(startDate) => handleChange({ startDate })} className="form-control" />
												</div>
												<div className='form-group col-md-4'>
													<label>Bonus Multiplier Type*</label>
													<CustomSelect
														{...{
															value: data.typeId,
															setValue: handleChangeType,
															options: types,
														}}
													/>
												</div>
											</div>
											<Button className="mr-2" variant="success" onClick={() => handleSubmit()}>
												Add
											</Button>
											<Button variant="danger" onClick={handleCancel}>
												Cancel
											</Button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-12">
							<div className="card">
								<div className="card-header">
									<h4 className="card-title">Change Restriction Status for HODL User</h4>
								</div>
								<div className="card-body">
									<div className="basic-form">
										<form onSubmit={(e) => e.preventDefault()}>
											<div className="form-row">
												<div className="form-group col-md-4">
													<label>Email*</label>
													<input
														value={restrictionData.email}
														type="text"
														className="form-control"
														placeholder="Email"
														onChange={(e) => {
															handleRestrictionChange({ email: e.target.value })
														}}
													/>
												</div>
												<div className='form-group col-md-2'>
													<label>Is Restricted</label>
													<input
														value={restrictionData?.isRestricted}
														type='number'
														className='form-control'
														min={0}
  														max={1}
														placeholder='1: restricted, 0: otherwise'
														onChange={(e) => handleRestrictionChange({ isRestricted: Number(e.target.value) })}
													/>
												</div>
											</div>
											<Button className="mr-2" variant="success" onClick={() => handleRestrictionSubmit()}>
												Save
											</Button>
											<Button variant="danger" onClick={handleCancel}>
												Cancel
											</Button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default DiamondCircleForm
