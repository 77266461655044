import { Button } from 'react-bootstrap'
import useLockedTRSForm from './useLockedTRSForm'
import { LoadMask } from 'components/common/loadMask'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { CustomSelect } from 'components/common/customSelect'

const LockedTRSForm = () => {
	const {
		types,
		data,
		loading,
		handleCancel,
		handleChange,
		handleChangeType,
		handleSubmit
	} = useLockedTRSForm()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Add a new user to a Locked TRS Bucket</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-4">
												<label>Email*</label>
												<input
													value={data.email}
													type="text"
													className="form-control"
													placeholder="Email"
													onChange={(e) => {
														handleChange({ email: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Start Date*</label>
												<DatePicker selected={data?.startDate} onChange={(startDate) => handleChange({ startDate })} className="form-control" />
											</div>
                                            <div className="form-group col-md-4">
												<label>Total Amount*</label>
                                                <input
                                                    value={data?.totalAmount}
                                                    type='number'
                                                    className='form-control'
                                                    placeholder='E.g. 1000 FAN'
                                                    onChange={(e) => handleChange({ totalAmount: Number(e.target.value) })}
                                                />
											</div>
                                            <div className="form-group col-md-4">
												<label>Vesting Period*</label>
												<input
                                                    value={data?.vestingPeriod}
                                                    type='number'
                                                    className='form-control'
                                                    placeholder='E.g. 4 months'
                                                    onChange={(e) => handleChange({ vestingPeriod: Number(e.target.value) })}
                                                />
											</div>
											<div className='form-group col-md-4'>
												<label>Tag*</label>
												<CustomSelect
													{...{
														value: data.typeId,
														setValue: handleChangeType,
														options: types,
													}}
												/>
											</div>
										</div>
										<Button className="mr-2" variant="success" onClick={handleSubmit}>
											Add
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default LockedTRSForm
