import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getLockedTRSUsers, deployLockedTRSVestingContract } from 'services/LockedTRSService'
import { useHistory } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'

const useLockedTRSTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [users, setUsers] = useState({
		rows: [],
		count: 0,
	})
    const [deployVestingContractId, setDeployVestingContractId] = useState(null)

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setUsers(data)
		}
	}

	const [{ loading: loadingUsers }, fetchUsers] = useAxios(
		getLockedTRSUsers({ limit, offset, search }),
		afterFetchUsers
	)

    const afterDeploy = (_data, error) => {
		if (!error) {
			const message = `Vesting Contract Deployed Successfully for user ${_data.email}!`
			showMessage({ message })
			fetchUsers() // Refresh the users list after successful deployment
		} else {
			showMessage({ message: _data.message || 'Deployment failed', type: 'error' })
			setDeployVestingContractId(null)
		}
	}

	const [{ loading: deploying }, deployVestingContract] = useAxios(
        deployLockedTRSVestingContract({ id: deployVestingContractId }),
        afterDeploy
    )

	useEffect(() => {
		fetchUsers()
	}, [limit, offset, search])

    // Trigger deployment when deployVestingService is set
    useEffect(() => {
        if (deployVestingContractId) {
            deployVestingContract()
        }
    }, [deployVestingContractId])

	const loading = loadingUsers || deploying

	return {
		limit,
		loading,
		data: users,
		setOffset,
		setLimit,
		setSearch,
        setDeployVestingContractId
	}
}

export default useLockedTRSTable
